import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/layout/MainLayout.vue'
import { AmplifyEventBus } from 'aws-amplify-vue'
import { getUser } from '../utils/auth.js'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: Layout,
      redirect: '/home',
      children: [
        {
          path: '/home',
          name: 'home',
          component: () => import('../views/pages/Home.vue')
        },
        {
          path: '/products',
          props: route => ({ page: route.query.page, size: route.query.size, search: route.query.search, tab: route.query.tab, field: route.query.field, sort: route.query.sort }),
          name: 'products',
          component: () => import('../views/pages/product/productList.vue')
        },
        {
          path: '/update-product/:product_id',
          name: 'updateProduct',
          component: () =>
            import('../views/pages/product/productUpdateForm.vue')
        },
        {
          path: '/add-product',
          name: 'addProduct',
          component: () => import('../views/pages/product/productForm.vue')
        },
        {
          path: '/product',
          name: 'Product',
          component: () =>
            import('../views/pages/product/product.vue')
        },
        {
          path: '/inventory',
          props: route => ({ page: route.query.page, size: route.query.size, search: route.query.search, field: route.query.field, sort: route.query.sort }),
          name: 'inventory',
          component: () => import('../views/pages/inventory/stockList.vue')
        },
        {
          path: '/stock-detail/:variant_id',
          name: 'stockDetail',
          component: () => import('../views/pages/inventory/stockDetail.vue')
        },
        {
          path: '/transfer',
          props: route => ({ page: route.query.page, size: route.query.size, search: route.query.search, tab: route.query.tab }),
          name: 'transfer',
          component: () => import('../views/pages/transfer/transferList.vue')
        },
        {
          path: '/add-transfer',
          name: 'addTransfer',
          component: () => import('../views/pages/transfer/transferForm.vue')
        },
        {
          path: '/edit-transfer/:transfer_id',
          name: 'editTransfer',
          component: () => import('../views/pages/transfer/editTransfer.vue')
        },
        {
          path: '/transferDetail/:transfer_id',
          name: 'transferDetail',
          component: () => import('../views/pages/transfer/transferDetail.vue')
        },
        {
          path: '/receive-transfer-detail/:transfer_id',
          name: 'receiveTransferDetail',
          component: () => import('../views/pages/transfer/receiveTransferDetail.vue')
        },
        {
          path: '/return',
          props: route => ({ page: route.query.page, size: route.query.size, search: route.query.search, tab: route.query.tab }),
          name: 'return',
          component: () => import('../views/pages/return/returnList.vue')
        },
        {
          path: '/add-return/:transfer_id',
          name: 'addReturn',
          component: () => import('../views/pages/return/returnForm.vue')
        },
        {
          path: '/edit-return/:transfer_id',
          name: 'editReturn',
          component: () => import('../views/pages/return/editReturn.vue')
        },
        {
          path: '/returnDetail/:transfer_id',
          name: 'returnDetail',
          component: () => import('../views/pages/return/returnDetail.vue')
        },
        {
          path: '/receive-return-detail/:transfer_id',
          name: 'receiveReturnDetail',
          component: () => import('../views/pages/return/receiveReturnDetail.vue')
        },
        {
          path: '/supplier',
          props: route => ({ page: route.query.page, size: route.query.size }),
          name: 'supplier',
          component: () => import('../views/pages/supplier/supplierList.vue')
        },
        {
          path: '/supplier-add',
          name: 'supplierAddForm',
          component: () => import('../views/pages/supplier/supplierAddForm.vue')
        },
        {
          path: '/edit-supplier/:supplier_id',
          name: 'editSupplier',
          component: () => import('../views/pages/supplier/supplierUpdateForm.vue')
        },
        {
          path: '/brandList',
          name: 'brandList',
          component: () => import('../views/pages/brand/brandList.vue')
        },
        {
          path: '/brand-add',
          name: 'brandAddForm',
          component: () => import('../views/pages/brand/brandAddForm.vue')
        },
        {
          path: '/edit-brand/:brand_id',
          name: 'editBrand',
          component: () => import('../views/pages/brand/brandUpdateForm.vue')
        },
        {
          path: '/cc-orders',
          props: route => ({ search_text: route.query.search_text, page: route.query.page, size: route.query.size }),
          name: 'ccOrders',
          component: () => import('../views/pages/ccOrders/ccOrders.vue')
        },
        {
          path: '/warehouse',
          props: route => ({ page: route.query.page, size: route.query.size, search: route.query.search }),
          name: 'warehouseList',
          component: () => import('../views/pages/warehouse/warehouseList.vue')
        },
        {
          path: '/add-warehouse',
          name: 'addWarehouse',
          component: () => import('../views/pages/warehouse/warehouseForm.vue')
        },
        {
          path: '/edit-warehouse/:warehouse_id',
          name: 'editWarehouse',
          component: () => import('../views/pages/warehouse/warehouseForm.vue')
        },
        {
          path: '/userList',
          name: 'userList',
          component: () => import('../views/pages/user/userList.vue')
        },
        {
          path: '/order',
          props: route => ({ page: route.query.page, size: route.query.size, search: route.query.search, tab: route.query.tab }),
          name: 'orderList',
          component: () => import('../views/pages/order/orderList.vue')
        },
        {
          path: '/order-group',
          props: route => ({ page: route.query.page, size: route.query.size, search: route.query.search }),
          name: 'orderGroup',
          component: () => import('../views/pages/order/orderGroup.vue')
        },
        {
          path: '/order-collect',
          props: route => ({ page: route.query.page, size: route.query.size, search: route.query.search }),
          name: 'orderCollect',
          component: () => import('../views/pages/order/orderCollect.vue')
        },
        {
          path: '/add-order',
          name: 'addOrder',
          component: () => import('../views/pages/order/addOrder.vue')
        },
        {
          path: '/order-detail/:id',
          name: 'orderDetail',
          component: () => import('../views/pages/order/orderDetail.vue')
        },
        {
          path: '/code-discount',
          props: route => ({ page: route.query.page, size: route.query.size }),
          name: 'codeDiscount',
          component: () => import('../views/pages/discount/codeDiscount.vue')
        },
        {
          path: '/auto-discount',
          props: route => ({ page: route.query.page, size: route.query.size }),
          name: 'autoDiscount',
          component: () => import('../views/pages/discount/autoDiscount.vue')
        },
        {
          path: '/add-discount',
          name: 'addDiscount',
          component: () => import('../views/pages/discount/addDiscount.vue')
        },
        {
          path: '/detail-discount/:id',
          name: 'detailDiscount',
          component: () => import('../views/pages/discount/detailDiscount.vue')
        },
        {
          path: '/banner-list',
          props: route => ({ page: route.query.page, size: route.query.size }),
          name: 'bannerList',
          component: () => import('../views/pages/banner/bannerList.vue')
        },
        {
          path: '/add-banner',
          name: 'addBanner',
          component: () => import('../views/pages/banner/addBanner.vue')
        },
        {
          path: '/banner-edit/:id',
          name: 'bannerEdit',
          component: () => import('../views/pages/banner/bannerEdit.vue')
        },
        {
          path: '/banner-detail/:id',
          name: 'bannerDetail',
          component: () => import('../views/pages/banner/bannerDetail.vue')
        },
        {
          path: '/add-block',
          name: 'addBlock',
          component: () => import('../views/pages/block/blockAddForm.vue')
        },
        {
          path: '/block-list',
          props: route => ({ page: route.query.page, size: route.query.size }),
          name: 'blockList',
          component: () => import('../views/pages/block/blockList.vue')
        },
        {
          path: '/block-edit/:id',
          name: 'blockEdit',
          component: () => import('../views/pages/block/blockUpdateForm.vue')
        },
        {
          path: '/cost',
          props: route => ({ page: route.query.page, size: route.query.size }),
          name: 'cost',
          component: () => import('../views/pages/cost/cost.vue')
        },
        {
          path: '/rider-list',
          name: 'riderList',
          component: () => import('../views/pages/rider/riderList.vue')
        },
        {
          path: '/edit-rider/:user_name',
          name: 'editRider',
          component: () => import('../views/pages/rider/editRider.vue')
        }
      ],
      meta: { requiresAuth: true }
    },
    {
      path: '/signIn',
      name: 'signIn',
      component: () => import('../views/pages/user/login.vue'),
      meta: { requiresAuth: false }
    },
    {
      path: '/signUp',
      name: 'signUp',
      component: () => import('../views/pages/user/signUp.vue'),
      meta: { requiresAuth: false }
    }
  ]
})

AmplifyEventBus.$on('authState', async state => {
  const pushPathes = {
    signedOut: () => {
      router.push({ path: '/signIn' })
    },
    signUp: () => {
      router.push({ path: '/signUp' })
    },
    // confirmSignUp: () => {
    //   router.push({ path: '/signUpConfirm' })
    // },
    signIn: () => {
      router.push({ path: '/signIn' })
    },
    signedIn: () => {
      router.push({ path: '/home' })
    }
  }
  if (typeof pushPathes[state] === 'function') {
    pushPathes[state]()
  }
})

router.beforeResolve(async (to, from, next) => {
  const user = await getUser()
  if (!user) {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      return next({
        path: '/signIn'
      })
    }
  } else {
    if (
      to.matched.some(
        record =>
          typeof record.meta.requiresAuth === 'boolean' &&
          !record.meta.requiresAuth
      )
    ) {
      return next({
        path: '/'
      })
    }
    if (to.name == null) {
      return next({
        path: '/'
      })
    }
  }
  return next()
})

export default router
