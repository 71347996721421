var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-aside',{class:_vm.isSun ? 'main-sidebar' : 'main-sidebar1'},[_c('ul',{staticClass:"main-menu"},[_c('li',{class:((_vm.$route.name === 'home') ? 'active': '')},[_c('router-link',{attrs:{"to":"/"}},[_c('home-icon',{staticClass:"icon",attrs:{"size":24}}),_c('span',{staticClass:"text"},[_vm._v("Нүүр")])],1)],1),_c('li',{class:(( [
        'supplierAddForm',
        'warehouseList',
        'addWarehouse',
        'editWarehouse',
        'brandList',
        'editSupplier',
        'brandAddForm',
        'editBrand',
        'supplier',
        'userList'].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/warehouse"}},[_c('registry-icon',{staticClass:"icon",attrs:{"size":24}}),_c('span',{staticClass:"text"},[_vm._v("Агуулах")])],1)],1),_c('li',{class:(( [
        'products',
        'updateProduct',
        'addProduct'
        ].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/products"}},[_c('format-list-bulleted-icon',{staticClass:"icon",attrs:{"size":24}}),_c('span',{staticClass:"text"},[_vm._v("Бүтээгдэхүүнүүд")])],1)],1),_c('li',{class:(( [
        'inventory',
        'stockDetail'
        ].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/inventory"}},[_c('shopping-icon',{staticClass:"icon",attrs:{"size":24}}),_c('span',{staticClass:"text"},[_vm._v("Хувилбарууд")])],1)],1),_c('li',{class:(( [
        'transfer',
        'transferList',
        'addTransfer',
        'editTransfer',
        'transferDetail',
        'receiveTransferDetail'].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/transfer"}},[_c('menu-icon',{staticClass:"icon",attrs:{"size":20}}),_c('span',{staticClass:"text"},[_vm._v("Татан авалт")])],1)],1),_c('li',{class:(( [
        'return',
        'addReturn',
        'editReturn',
        'returnDetail',
        'receiveReturnDetail'].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/return"}},[_c('refresh',{staticClass:"icon",attrs:{"size":24}}),_c('span',{staticClass:"text"},[_vm._v("Буцаалт")])],1)],1),_c('li',{class:(( [
         'orderList'].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/order"}},[_c('order-icon',{staticClass:"icon",attrs:{"size":24}}),_c('span',{staticClass:"text"},[_vm._v("Нийт захиалгууд")])],1)],1),_c('li',{class:(( [
        'orderGroup'].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/order-group"}},[_c('account-group',{staticClass:"icon",attrs:{"size":24}}),_c('span',{staticClass:"text"},[_vm._v(" Групп захиалга ")])],1)],1),_c('li',{class:(( [
        'orderCollect'].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/order-collect"}},[_c('ballot-recount-icon',{staticClass:"icon",attrs:{"size":24}}),_c('span',{staticClass:"text"},[_vm._v(" Захиалга цуглуулга ")])],1)],1),_c('li',{class:(( [
        'Product'].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/product"}},[_c('currency-mnt-icon',{staticClass:"icon",attrs:{"size":20}}),_c('span',{staticClass:"text"},[_vm._v("Үнэ батлуулах хуудас")])],1)],1),_c('li',{class:((['cost'].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/cost"}},[_c('credit-card',{staticClass:"icon",attrs:{"size":24}}),_c('span',{staticClass:"text"},[_vm._v("Бүтээгдэхүүний өртөг")])],1)],1),_c('li',{class:(( [
        'riderList',
        'editRider' ].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/rider-list"}},[_c('car',{staticClass:"icon",attrs:{"size":24}}),_c('span',{staticClass:"text"},[_vm._v("Жолоочийн удирдлага")])],1)],1),_c('li',{class:(( [
        'ccOrders' ].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/cc-orders"}},[_c('Phone',{staticClass:"icon",attrs:{"size":24}}),_c('span',{staticClass:"text"},[_vm._v("PD явц")])],1)],1)]),_c('el-row',{staticClass:"mt10",attrs:{"align":"center"}},[_c('el-col',{staticClass:"text-center"},[_c('i',{class:_vm.isSun ? 'el-icon-moon mr5 iconSun' : 'el-icon-moon mr5 iconDark'}),_c('el-switch',{attrs:{"width":40},model:{value:(_vm.isSun),callback:function ($$v) {_vm.isSun=$$v},expression:"isSun"}}),_c('i',{class:_vm.isSun ? 'el-icon-sunny ml5 iconSun' : 'el-icon-sunny ml5 iconDark'})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }