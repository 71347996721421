<template>
  <el-aside :class="isSun ? 'main-sidebar' : 'main-sidebar1'">
    <ul class="main-menu">
      <li :class="(($route.name === 'home') ? 'active': '')">
        <router-link to="/">
          <home-icon class="icon" :size="24" />
          <span class="text">Нүүр</span>
        </router-link>
      </li>
      <li :class="(( [
          'supplierAddForm',
          'warehouseList',
          'addWarehouse',
          'editWarehouse',
          'brandList',
          'editSupplier',
          'brandAddForm',
          'editBrand',
          'supplier',
          'userList'].includes($route.name )) ? 'active': '')">
        <router-link to="/warehouse">
          <registry-icon class="icon" :size="24" />
          <span class="text">Агуулах</span>
        </router-link>
      </li>
        <li :class="(( [
          'products',
          'updateProduct',
          'addProduct'
          ].includes($route.name )) ? 'active': '')">
            <router-link to="/products">
            <format-list-bulleted-icon class="icon" :size="24" />
              <span class="text">Бүтээгдэхүүнүүд</span>
            </router-link>
          </li>
       <li :class="(( [
          'inventory',
          'stockDetail'
          ].includes($route.name )) ? 'active': '')">
            <router-link to="/inventory">
              <shopping-icon class="icon" :size= "24" />
              <span class="text">Хувилбарууд</span>
            </router-link>
        </li>
      <li :class="(( [
          'transfer',
          'transferList',
          'addTransfer',
          'editTransfer',
          'transferDetail',
          'receiveTransferDetail'].includes($route.name )) ? 'active': '')">
        <router-link to="/transfer">
          <menu-icon class="icon" :size="20" />
          <span class="text">Татан авалт</span>
        </router-link>
        <li :class="(( [
          'return',
          'addReturn',
          'editReturn',
          'returnDetail',
          'receiveReturnDetail'].includes($route.name )) ? 'active': '')">
          <router-link to="/return">
            <refresh class="icon" :size ="24"></refresh>
              <span class="text">Буцаалт</span>
            </router-link>
          </li>
      <li :class="(( [
           'orderList'].includes($route.name )) ? 'active': '')">
        <router-link to="/order">
          <order-icon class="icon" :size="24" />
          <span class="text">Нийт захиалгууд</span>
        </router-link>
        <li :class="(( [
          'orderGroup'].includes($route.name )) ? 'active': '')">
           <router-link to="/order-group">
          <account-group class="icon" :size="24" />
           <span class="text"> Групп захиалга </span>
           </router-link>
       </li>
      <li :class="(( [
          'orderCollect'].includes($route.name )) ? 'active': '')">
           <router-link to="/order-collect">
          <ballot-recount-icon class="icon" :size="24" />
           <span class="text"> Захиалга цуглуулга </span>
           </router-link>
       </li>
       <li :class="(( [
          'Product'].includes($route.name )) ? 'active': '')">
        <router-link to="/product">
          <currency-mnt-icon class="icon" :size="20" />
          <span class="text">Үнэ батлуулах хуудас</span>
        </router-link>
      </li>
          <li :class="((['cost'].includes($route.name )) ? 'active': '')">
           <router-link to="/cost">
            <credit-card class="icon" :size="24" />
            <span class="text">Бүтээгдэхүүний өртөг</span>
           </router-link>
       </li>
       <li :class="(( [
          'riderList',
          'editRider',].includes($route.name )) ? 'active': '')">
          <router-link to="/rider-list">
            <car class="icon" :size ="24"></car>
              <span class="text">Жолоочийн удирдлага</span>
            </router-link>
          </li>
       <li :class="(( [
          'ccOrders',].includes($route.name )) ? 'active': '')">
          <router-link to="/cc-orders">
            <Phone class="icon" :size ="24"></Phone>
              <span class="text">PD явц</span>
            </router-link>
          </li>
    </ul>
    <el-row align="center" class="mt10">
      <el-col class="text-center">
        <i :class="isSun ? 'el-icon-moon mr5 iconSun' : 'el-icon-moon mr5 iconDark'"></i><el-switch :width="40" v-model="isSun"></el-switch><i :class="isSun ? 'el-icon-sunny ml5 iconSun' : 'el-icon-sunny ml5 iconDark'"></i>
      </el-col>
    </el-row>
  </el-aside>
</template>
<script>
import MenuIcon from 'vue-material-design-icons/Tag.vue'
import HomeIcon from 'vue-material-design-icons/Home.vue'
import OrderIcon from 'vue-material-design-icons/BasketFill.vue'
import RegistryIcon from 'vue-material-design-icons/City.vue'
import Refresh from 'vue-material-design-icons/Refresh.vue'
import BallotRecountIcon from 'vue-material-design-icons/BallotRecount.vue'
import ShoppingIcon from 'vue-material-design-icons/Shopping.vue'
import FormatListBulletedIcon from 'vue-material-design-icons/FormatListBulleted.vue'
import CurrencyMntIcon from 'vue-material-design-icons/CurrencyMnt.vue'
import CreditCard from 'vue-material-design-icons/CreditCard.vue'
import AccountGroup from 'vue-material-design-icons/AccountGroup.vue'
import Car from 'vue-material-design-icons/Car.vue'
import Phone from 'vue-material-design-icons/Phone.vue'

export default {
  components: {
    HomeIcon,
    Phone,
    MenuIcon,
    OrderIcon,
    RegistryIcon,
    Refresh,
    BallotRecountIcon,
    ShoppingIcon,
    FormatListBulletedIcon,
    CurrencyMntIcon,
    CreditCard,
    AccountGroup,
    Car
  },
  data () {
    return {
      route: '',
      isSun: true
    }
  }
}
</script>
<style scoped>
.iconDark {
    color: white;
    font-size: 1rem;
  }
  .iconSun {
    color: rgb(48, 65, 86);
    font-size: 1rem;
  }
</style>
