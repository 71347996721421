// URL
const BASE_PATH = 'https://rest.toktok.mn/inventory'
const BASE_SECOND_PATH = 'https://api.toktokmall.mn/api'
const BASE_TIRTH_PATH = 'https://rest.toktok.mn/other'

// Inventory
const BASE_INVENTORY = '/inventory'
const BASE_SET_VARIANT_RACK_POSITION = '/setVariantRackPosition'
const GET_EXPIRED_VARIANTS = '/getExpiredVariants'
const BASE_SET_VARIANT_BARCODE_AND_EXPIREDDATE = '/setVariantBarCodeAndExpiredDate'

// Product
const BASE_PRODUCT = '/product'
const BASE_PRODUCTS_WITH_VARIANT = '/getProductsWithVariant'
const BASE_GET_ONE_PRODUCT = '/getOneProduct'
const BASE_GET_PRODUCT_BY_VARIANT_ID = '/getProductSameVariants'
const BASE_GET_SEARCH_PRODUCT_NAME = '/getSearchProductName'
const BASE_GET_OPTIONS_NAME = '/getOptionsName'
const BASE_GET_OPTIONS_VALUE = '/getOptionsValue'
const BASE_GET_PROPRTY_NAME = '/getPropertiesName'
const BASE_GET_PROPRTY_VALUE = '/getPropertiesValue'
const BASE_GET_ALL_PRODUCT = '/getAllProducts'

// CC order
const BASE_GET_COMPLAINT_PATH = 'https://i1ujra230a.execute-api.ap-southeast-1.amazonaws.com/prod/cc/'
const BASE_GET_CC = 'https://rest.toktok.mn/cc/'
// Category
const BASE_CATEGORY = '/category'

// Cost
const BASE_COST = '/getProductPrice'

// getRiderUser
const GET_RIDER_USERS = 'https://rest.toktok.mn/inventory/rider/getUsers'
const GET_ACTIVE_USERS = 'https://rest.toktok.mn/inventory/rider/getActiveUsers'
const CONFIRM_RIDER_USER = 'https://rest.toktok.mn/inventory/rider/confirmSignUp'
const CHANGE_RIDER = 'https://rest.toktok.mn/inventory/rider/setPassword'
const GET_USER = 'https://rest.toktok.mn/inventory/rider/getUser'
const UPDATE_RIDER = 'https://rest.toktok.mn/inventory/rider/updateUser'
const REMOVE_RIDER = 'https://rest.toktok.mn/inventory/rider/removeUser'

// Supplier
const BASE_SUPPLIER = '/supplier'
const BASE_GET_ONESUPPLIER_BRANDS = '/getSupplierBrands'
const BASE_GET_SUPPLIERS_BY_WAREHOUSE_ID = '/getSuppliersByWarehouseId'
const BASE_GET_BRANDS_BY_SUPLLIER_IDS = '/getBrandsBySupplierIds'

// Variant
const BASE_ADD_VARIANT = '/addVariant'
const BASE_UPDATE_VARIANT = '/updateVariant'
const BASE_GET_ALL_VARIANTS = '/getAllVariants'
const BASE_CHANGE_PRODUCT_COUNT = '/changeCount'

// Transfer
const BASE_TRANSFER = '/transfer'
const BASE_UNCHECKED_TRANSFER_PRODUCTS = '/getProductsByTransfer'
const BASE_CHANGE_TRANSFER_STATUS = '/changeTransferStatus'
const BASE_GET_ALL_REQURIED_PRODUCT = '/getAllRequiredProduct'

// RETURN
const BASE_RETURN = '/return'
const BASE_UNCHECKED_RETURN_PRODUCTS = '/getProductsByReturn'
const BASE_CHANGE_RETURN_STATUS = '/changeReturnStatus'

// Warehouse
const BASE_WAREHOUSE = '/warehouse'

// Brand
const BASE_BRAND = '/brand'
const BASE_GET_BRANDS_BY_SUPPLIER_ID = '/getSupplierBrands'

// Utillity
const BASE_IMAGE_UPLOAD = '/imageUpload'

// Stats
const GET_STATS = '/getStats'

// Users
const BASE_GET_USERS = '/getUsers'
const BASE_CHANGE_PASSWORD = '/changePassword'

// Groups
const BASE_GET_GROUPS = '/getGroups'
const BASE_CREATE_GROUP = '/createGroup'
const BASE_DELETE_GROUP = '/deleteGroup'
const BASE_ADD_TO_GROUP = '/addUserToGroup'

// Order
const BASE_ORDER = '/order'
const BASE_ORDERS_DETAILS = '/getOrdersDetails'
const BASE_ORDER_FULLFILLMENT = '/updateOrderItem'
const BASE_ORDER_DRIVERS = '/order'
const BASE_GROUP_ORDERS = '/groupOrder'

// Basket
const BASE_CREATE_BASKET = '/basket'
const BASE_ADD_BASKET_ITEM = '/addProduct'
const BASE_SET_BASKET_ITEMS = '/addProducts'
const BASE_REMOVE_BASKET_ITEM = '/removeProduct'
const BASE_CREATE_ORDER = '/basketToOrder'
const BASE_ADD_USER = '/setEmailPhone'
const BASE_ADD_ADDRESS = '/setAddress'
const BASE_ADD_DISCOUNT_TO_BASKET = '/addCodeDiscountBasket'
const BASE_REMOVE_DISCOUNT_TO_BASKET = '/removeDiscountFromBasket'
// Discount
const BASE_DISCOUNT = '/discount'
const BASE_ADD_DISCOUNT = '/discount'
const BASE_GET_ONE_DISCOUNT = '/discount'
// Banner
const BASE_BANNER = '/banner'

// SuperBlock
const SUPER_BLOCK = '/superBlock'

// Address
const BASE_ADDRESS = '/addressSearch'

// search_text
const BASE_SEARCH_TEXT = '/supplier'

// driver

const BASE_DRIVER_ASSIGN = '/driver/driverAssign'
const BASE_GET_ORDER_DRIVER = '/driver/getOrdersByDriver'
const BASE_DRIVER_OUT_DRIVER = '/driver/driverOutByDriver'
const BASE_CHECK_BALANCE = '/driver/checkBalance'
const BASE_DRIVER_INBY_DRIVER = '/driver/driverInByDriver'
const BASE_ORDER_COMPLETE_DRIVER = '/driver/orderCompleteByDriver'
const BASE_GET_ONE_ORDER_DRIVER = '/driver/getOneOrderByDriver'
const BASE_DRIVER = '/driver'

export default {
  BASE_PATH: BASE_PATH,
  BASE_SECOND_PATH: BASE_SECOND_PATH,
  BASE_TIRTH_PATH: BASE_TIRTH_PATH,
  //
  BASE_INVENTORY: BASE_INVENTORY,
  BASE_SET_VARIANT_RACK_POSITION: BASE_SET_VARIANT_RACK_POSITION,
  GET_EXPIRED_VARIANTS: GET_EXPIRED_VARIANTS,
  BASE_SET_VARIANT_BARCODE_AND_EXPIREDDATE: BASE_SET_VARIANT_BARCODE_AND_EXPIREDDATE,
  //
  BASE_PRODUCT: BASE_PRODUCT,
  BASE_PRODUCTS_WITH_VARIANT: BASE_PRODUCTS_WITH_VARIANT,
  BASE_GET_ONE_PRODUCT: BASE_GET_ONE_PRODUCT,
  BASE_GET_PRODUCT_BY_VARIANT_ID: BASE_GET_PRODUCT_BY_VARIANT_ID,
  BASE_GET_SEARCH_PRODUCT_NAME: BASE_GET_SEARCH_PRODUCT_NAME,
  BASE_GET_OPTIONS_NAME: BASE_GET_OPTIONS_NAME,
  BASE_GET_OPTIONS_VALUE: BASE_GET_OPTIONS_VALUE,
  BASE_GET_PROPRTY_NAME: BASE_GET_PROPRTY_NAME,
  BASE_GET_PROPRTY_VALUE: BASE_GET_PROPRTY_VALUE,
  //
  BASE_GET_COMPLAINT_PATH: BASE_GET_COMPLAINT_PATH,
  BASE_GET_CC: BASE_GET_CC,
  //
  BASE_CATEGORY: BASE_CATEGORY,
  //
  BASE_SUPPLIER: BASE_SUPPLIER,
  BASE_GET_ONESUPPLIER_BRANDS: BASE_GET_ONESUPPLIER_BRANDS,
  BASE_GET_SUPPLIERS_BY_WAREHOUSE_ID: BASE_GET_SUPPLIERS_BY_WAREHOUSE_ID,
  BASE_GET_BRANDS_BY_SUPLLIER_IDS: BASE_GET_BRANDS_BY_SUPLLIER_IDS,
  //
  BASE_ADD_VARIANT: BASE_ADD_VARIANT,
  BASE_UPDATE_VARIANT: BASE_UPDATE_VARIANT,
  BASE_GET_ALL_VARIANTS: BASE_GET_ALL_VARIANTS,
  BASE_CHANGE_PRODUCT_COUNT: BASE_CHANGE_PRODUCT_COUNT,
  //
  BASE_TRANSFER: BASE_TRANSFER,
  BASE_UNCHECKED_TRANSFER_PRODUCTS: BASE_UNCHECKED_TRANSFER_PRODUCTS,
  BASE_CHANGE_TRANSFER_STATUS: BASE_CHANGE_TRANSFER_STATUS,
  BASE_GET_ALL_REQURIED_PRODUCT: BASE_GET_ALL_REQURIED_PRODUCT,
  //
  BASE_RETURN: BASE_RETURN,
  BASE_UNCHECKED_RETURN_PRODUCTS: BASE_UNCHECKED_RETURN_PRODUCTS,
  BASE_CHANGE_RETURN_STATUS: BASE_CHANGE_RETURN_STATUS,
  //
  BASE_WAREHOUSE: BASE_WAREHOUSE,
  //
  BASE_BRAND: BASE_BRAND,
  //
  BASE_COST: BASE_COST,
  //
  GET_STATS: GET_STATS,
  //
  BASE_GET_BRANDS_BY_SUPPLIER_ID: BASE_GET_BRANDS_BY_SUPPLIER_ID,
  BASE_IMAGE_UPLOAD: BASE_IMAGE_UPLOAD,
  //
  BASE_GET_USERS: BASE_GET_USERS,
  BASE_CHANGE_PASSWORD: BASE_CHANGE_PASSWORD,
  //
  BASE_GET_GROUPS: BASE_GET_GROUPS,
  BASE_CREATE_GROUP: BASE_CREATE_GROUP,
  BASE_DELETE_GROUP: BASE_DELETE_GROUP,
  BASE_ADD_TO_GROUP: BASE_ADD_TO_GROUP,
  //
  GET_RIDER_USERS: GET_RIDER_USERS,
  GET_ACTIVE_USERS: GET_ACTIVE_USERS,
  CONFIRM_RIDER_USER: CONFIRM_RIDER_USER,
  CHANGE_RIDER: CHANGE_RIDER,
  GET_USER: GET_USER,
  UPDATE_RIDER: UPDATE_RIDER,
  REMOVE_RIDER: REMOVE_RIDER,
  //
  BASE_ORDER: BASE_ORDER,
  BASE_ORDERS_DETAILS: BASE_ORDERS_DETAILS,
  BASE_ORDER_FULLFILLMENT: BASE_ORDER_FULLFILLMENT,
  BASE_ORDER_DRIVERS: BASE_ORDER_DRIVERS,
  BASE_GROUP_ORDERS: BASE_GROUP_ORDERS,
  //
  BASE_CREATE_BASKET: BASE_CREATE_BASKET,
  BASE_ADD_BASKET_ITEM: BASE_ADD_BASKET_ITEM,
  BASE_SET_BASKET_ITEMS: BASE_SET_BASKET_ITEMS,
  BASE_REMOVE_BASKET_ITEM: BASE_REMOVE_BASKET_ITEM,
  BASE_CREATE_ORDER: BASE_CREATE_ORDER,
  BASE_ADD_USER: BASE_ADD_USER,
  BASE_ADD_ADDRESS: BASE_ADD_ADDRESS,
  BASE_ADD_DISCOUNT_TO_BASKET: BASE_ADD_DISCOUNT_TO_BASKET,
  BASE_REMOVE_DISCOUNT_TO_BASKET: BASE_REMOVE_DISCOUNT_TO_BASKET,
  //
  BASE_DISCOUNT: BASE_DISCOUNT,
  BASE_ADD_DISCOUNT: BASE_ADD_DISCOUNT,
  BASE_GET_ONE_DISCOUNT: BASE_GET_ONE_DISCOUNT,
  //
  BASE_BANNER: BASE_BANNER,
  //
  SUPER_BLOCK: SUPER_BLOCK,
  //
  BASE_ADDRESS: BASE_ADDRESS,
  //
  BASE_SEARCH_TEXT: BASE_SEARCH_TEXT,
  //
  BASE_DRIVER_ASSIGN: BASE_DRIVER_ASSIGN,
  BASE_GET_ORDER_DRIVER: BASE_GET_ORDER_DRIVER,
  BASE_DRIVER_OUT_DRIVER: BASE_DRIVER_OUT_DRIVER,
  BASE_CHECK_BALANCE: BASE_CHECK_BALANCE,
  BASE_DRIVER_INBY_DRIVER: BASE_DRIVER_INBY_DRIVER,
  BASE_ORDER_COMPLETE_DRIVER: BASE_ORDER_COMPLETE_DRIVER,
  BASE_GET_ONE_ORDER_DRIVER: BASE_GET_ONE_ORDER_DRIVER,
  BASE_DRIVER: BASE_DRIVER,
  BASE_GET_ALL_PRODUCT: BASE_GET_ALL_PRODUCT
}
